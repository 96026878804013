import React from "react";
import InvoicePage from "./components/InvoicePage";

function App() {
  return (
    <div className="App">
      <InvoicePage />
    </div>
  );
}

export default App;
