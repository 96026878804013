export const initialProductLine = {
  description: "",
  quantity: "1",
  rate: "0.00",
};

export const initialInvoice = {
  logo: "",
  logoWidth: 100,
  title: "INVOICE",
  companyName: "",
  name: "",
  companyAddress: "",
  companyAddress2: "",
  companyCountry: "United States",
  fromTo: "From: ",
  billTo: "To: ",
  clientName: "",
  clientAddress: "",
  clientAddress2: "",
  clientCountry: "United States",
  invoiceTitleLabel: "Invoice No: ",
  invoiceTitle: "",
  invoiceDateLabel: "Invoice Date: ",
  invoiceDate: "",
  invoiceDueDateLabel: "Due Date: ",
  invoiceDueDate: "",
  productLineDescription: "Item Description",
  productLineQuantity: "Qty",
  productLineQuantityRate: "Rate",
  productLineQuantityAmount: "Amount",
  productLines: [
    {
      description: "",
      quantity: "0",
      rate: "0",
    },
  ],
  subTotalLabel: "Sub Total",
  taxLabel: "Sale Tax (10%)",
  totalLabel: "TOTAL",
  currency: "$",
  notesLabel: "Notes",
  notes: "It was great doing business with you.",
  termLabel: "Terms & Conditions",
  term: "Please make the payment by the due date.",
  username: "",
  userAddress: "",
  userCity: "",
  userCountry: "",
};
